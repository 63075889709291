
import "@/assets/sass/template.scss";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import TableTab from "@/components/kt-datatable/TableTab.vue";
import PaginationUtill from "../util/PaginationUtill";

export default defineComponent({
  name: "MeterType",

  components: {
    TableTab,
  },
  setup() {
    const Chart2Series = ref([]) as any;
    const pageheader = "Meter Type Report";
    const showprofile = ref();
    const ebchartType = ref("bar");
    const gaschartType = ref("bar");
    const addition = ref(false);
    const removal = ref(false);

    const serieslabel = ref([""]);
    const seriesvalue = ref([0]);
    const loading = ref<boolean>(false);
    interface Tableheader {
      name: string;
      key: string;
      sortable: boolean;
    }

    const TableHeaders = ref<Array<Tableheader>>();
    const OriginalTableHeaders: Array<Tableheader> =
      PaginationUtill.MeterTypeReport;
    const tableValues = ref([]);
    const colnames = OriginalTableHeaders.map((a) => a.key);

    const labels = ref([""]);
    const eboptions = ref([]) as any;
    eboptions.value = {
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,

          dataLabels: {
            position: "bottom",
          },
        },
      },
      series: [
        {
          data: seriesvalue,
        },
      ],
      colors: [
        "#F3B415",
        "#F27036",
        "#663F59",
        "#6A6E94",
        "#4E88B4",
        "#00A7C6",
        "#18D8D8",
        "#A9D794",
        "#46AF78",
        "#A93F55",
        "#8C5E58",
        "#2176FF",
        "#33A1FD",
        "#7A918D",
        "#BAFF29",
      ],
      labels: ["00", "01", "02", "03", "04", "05", "06", "07", "08"],

      chart: {
        toolbar: {
          show: true,
          autoSelected: "zoom",
        },
        width: 400,
        type: ebchartType,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: "bottom",
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: undefined,
        },
      },
      fill: {
        type: "gradient",
      },
      xaxis: {
        title: {
          text: "MPAN's",
          style: {
            fontSize: "13px",
            fontWeight: 600,
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs(" Meter Type Report", ["NA"]);
      addition.value = true;
      removal.value = false;
    });
    let aid = store.getters.AccountId;
    let domainName = store.getters.DomainName;
    const accountInfo: any = { accountid: aid, DomainName: domainName };

    ApiService.post("api/metertype/assetmetertype", accountInfo)
      .then(async ({ data }) => {
        await data;
        seriesvalue.value = data.mpancount.map((a) => parseInt(a.count));
        serieslabel.value = data.mpancount.map((a) => a.mpan);
        tableValues.value = data.elecdetails.map((a) => a);
        Chart2Series.value.push(data.igtNonigt.igt);
        Chart2Series.value.push(data.igtNonigt.nonigt);

      })
      .catch();

    function openAssetAddition() {
      console.log("clicked");
      addition.value = true;
      removal.value = false;
    }
    function openAssetRemoval() {
      addition.value = false;
      removal.value = true;
    }

    function changeChartType(type) {
      ebchartType.value = type;
    }

    function changeGasChartType(type) {
      gaschartType.value = type;
    }

    function showprofileType() {
      showprofile.value = !showprofile.value;
    }

    const gasoptions = {
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
        bar: {
          barHeight: "100%",
          distributed: true,

          dataLabels: {
            position: "bottom",
          },
        },
      },
      series: [
        {
          data: Chart2Series,
        },
      ],
      colors: [
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7",
      ],
      labels: ["IGT", "NON IGT"],
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: -30,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: "zoom",
        },
        width: 380,
        type: ebchartType,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: "bottom",
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: undefined,
        },
      },
      fill: {
        type: "gradient",
      },

      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 300,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    };

    return {
      pageheader,
      changeChartType,
      changeGasChartType,
      showprofileType,
      ebchartType,
      gaschartType,
      labels,
      TableHeaders,
      colnames,
      openAssetAddition,
      openAssetRemoval,
      addition,
      removal,
      tableValues,
      loading,
      eboptions,
      gasoptions,
      showprofile,
      seriesvalue,
      serieslabel,
      OriginalTableHeaders,
    };
  },
});
